import React from 'react';
import { graphql } from 'gatsby';

import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line

// Components
import Layout from '@components/layout';
import SimplePortableText from '@components/sanity/simplePortableText';
import { SanityImage } from '@components/sanity/sanityImage';
import { OtherMedia } from '../components/portals/otherMedia';
import { PageMeta } from '@components/pageMeta';
import { Share } from '../components/portals/share';

const Content = ({ title, category, date, featuredImage, content }) => {
  return (
    <div className='pt-36 lg:pt-64 relative z-10'>
      {/* Intro */}
      <div className='px-gutter text-teal'>
        <span className='blockH6'>
          {category?.title} - {date}
        </span>
        <h1 className='blockH1 mt-4 lg:mt-7'>{title}</h1>
        <SanityImage
          image={featuredImage}
          className='mt-12 lg:mt-20 rounded-xl'
        />
      </div>
      {/* Content */}
      <div className='mt-12 lg:mt-28 grid grid-cols-14 gap-y-12'>
        <div className='col-start-2 col-end-14 lg:col-end-11'>
          <SimplePortableText text={content} />
        </div>
        {/* Share buttons */}
        <div className='col-start-2 lg:col-start-12 col-end-14'>
          <Share />
        </div>
      </div>
    </div>
  );
};

const BlogTemplate = ({ data: { page } }) => {
  const { seo, slug } = page || {};

  return (
    <Layout>
      <PageMeta {...seo} />
      <Content {...page} />
      <OtherMedia currentSlug={slug?.current} />
    </Layout>
  );
};

export default BlogTemplate;

export const simpleBlogQuery = graphql`
  query standardBlogQuery($slug: String!) {
    page: sanityBlog(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }
      title
      content: _rawContent(resolveReferences: { maxDepth: 10 })
      date(locale: "NZ", formatString: "DD MMMM yyyy")
      slug {
        current
      }
      featuredImage {
        ...ImageWithPreview
      }
      category {
        title
      }
    }
  }
`;
