import React from 'react';

import { useBlogQuery } from '@querys/useBlogQuery';
import { StandardCarousel } from '../pageModules/carousels/standardCarousel';
import { usePortalQuery } from '@querys/usePortalQuery';

export const OtherMedia = ({ currentSlug }) => {
  const { blogPortal } = usePortalQuery();
  const { nodes } = useBlogQuery();

  //filter currentSlug from nodes
  const filteredNodes = nodes
    ?.filter((node) => node?.slug?.current !== currentSlug)
    .slice(0, 6);
  const slider = { slides: filteredNodes };
  return (
    <div className='pt-space-m pb-space-m relative z-10'>
      <StandardCarousel
        slider={slider}
        label='Other media'
        viewAll={blogPortal?.slug?.current}
      />
    </div>
  );
};
